<template>
  <section ref="element" class="pg-teaser-big">
    <!--    <p v-if="data.fieldEyebrow" class="eyebrow font-xl-bold-vl font-md-bold-vs">-->
    <!--      {{ data.fieldEyebrow }}-->
    <!--    </p>-->
    <h2
      v-if="data.title"
      ref="titleElement"
      class="title font-display-3xl-zuehlke"
    >
      {{ data.title }}
    </h2>
    <p
      v-if="data.text"
      ref="textElement"
      class="text font-lg-medium-vl font-md-medium-vs"
    >
      {{ data.text }}
    </p>
    <LinkHandler
      v-if="data.pgCtaLink?.url"
      class="button-primary cta -cta"
      :path="data.pgCtaLink.url"
      ><span class="label">{{ data.pgCtaLink.title }}</span>
    </LinkHandler>
    <div class="imagewrapper" role="presentation">
      <SvgHatchingLight class="hatching" />
      <MediaImage
        v-for="i in data.media"
        :key="i.id"
        :data="i"
        class="image"
        loading="lazy"
        :style="'FOCAL_POINT43'"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import type { ParagraphPgTeaserBig } from '~/drupal/types';
import LinkHandler from '~/components/links/LinkHandler.vue';
import { useIntersectionState } from '~/utils/useIntersectionState';
import SvgHatchingLight from '~/components/paragraphs/svgs/SvgHatchingLight.vue';
import { parseCubicBezier } from '~/utils/parseCubicBezier';

defineProps<{ data: ParagraphPgTeaserBig }>();

const element = ref<null | HTMLElement>(null);
const titleElement = ref<null | HTMLElement>(null);
const textElement = ref<null | HTMLElement>(null);

const { play, isAnimationFinished } = fadeInAnimation();

useIntersectionState(element, {
  onFirstEnter: play,
  onLeave() {
    isAnimationFinished.value = false;
  },
});

function fadeInAnimation() {
  const ease = parseCubicBezier('cubic-bezier(0.75,0,.25,1)');
  const duration = 1.1;
  const isAnimationFinished = ref<boolean>(false);
  const preferredMotion = usePreferredReducedMotion();

  const tl = gsap.timeline({
    paused: true,
    onComplete: function () {
      isAnimationFinished.value = true;
    },
  });

  onMounted(function () {
    if (preferredMotion.value === 'reduce') {
      return;
    }

    const split = new SplitText(titleElement.value, {
      type: 'lines,words',
      linesClass: 'line',
      wordsClass: 'word',
    });

    tl.fromTo(
      split.lines,
      { '--y': '-110%' },
      { '--y': 0, stagger: 0.1, ease, duration },
    )
      .fromTo(
        split.lines[0],
        {
          x: 0,
        },
        { x: Math.min(150, window.innerWidth * 0.1), ease, duration },
      )
      .fromTo(
        textElement.value,
        {
          opacity: -0,
        },
        { opacity: 1, ease: 'none', duration },
        '<',
      )
      .fromTo(
        '.pg-teaser-big .cta',
        { rotate: 22.5 },
        {
          rotate: 0,
          ease,
          duration: 0.5,
        },
        '<',
      )
      .fromTo(
        '.pg-teaser-big .cta',
        { opacity: 0 },
        {
          opacity: 1,
          ease: 'none',
          duration: 0.55,
          delay: 0.2,
        },
        '<',
      );
  });

  return {
    play,
    isAnimationFinished,
  };

  function play() {
    if (preferredMotion.value === 'reduce') {
      tl.progress(1);
    } else {
      tl.play(0);
    }
  }
}
</script>

<style scoped lang="scss">
.pg-teaser-big {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: auto;
  //margin-bottom: var(--base-component-padding-block);
  transition: background-color 1000ms;

  &.-was-in-viewport {
    background-color: var(--color-topaz-900);
    color: var(--color-topaz-050);
  }

  @media (--vs) {
    padding: var(--base-component-padding);
  }

  @media (--vl) {
    padding: var(--base-component-padding-block) 0 0
      var(--base-component-padding-inline);
    gap: 0 6.5rem;
  }

  //> .eyebrow {
  //  grid-column: 1 / -1;
  //  grid-row: 1 / 2;
  //
  //  @media (--vs) {
  //    padding-bottom: 2rem;
  //  }
  //
  //  @media (--vl) {
  //    padding-bottom: 3.25rem;
  //  }
  //}

  > .title {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
    z-index: 1;

    @media (--vs) {
      padding-bottom: 1.5rem;
      max-width: 90%;
    }

    @media (--vl) {
      padding: 0 7rem 6rem 0;
    }

    :deep(.line) {
      overflow-y: clip;

      > .word {
        transform: translate(0, var(--y));
      }
    }
  }

  > .text {
    grid-row: 3 / 4;

    @media (--vs) {
      grid-column: 1 / -1;
      padding-bottom: 1.5rem;
      text-wrap: pretty;
    }

    @media (--vl) {
      grid-column: 1 / 2;
      padding-bottom: 5.5rem;
    }
  }

  > .cta {
    transform-origin: -55px 40px;
    grid-column: 1 / 2;

    @media (--vs) {
      justify-self: end;
      grid-row: 5 / 6;
      grid-column: 2 / 3;
    }

    @media (--vl) {
      justify-self: start;
      grid-row: 4 / 5;
      margin-bottom: 8rem;
    }
  }

  > .imagewrapper {
    position: relative;
    align-self: end;
    margin-left: auto;
    width: 90%;
    grid-column: 2 / 3;

    @media (--vs) {
      grid-column: 1 / -1;
      grid-row: 4 / 5;
      padding-bottom: 1.5rem;
      margin-right: calc(
        -1 * var(--base-component-padding-inline)
      ); /* place image at the edge without padding of container */
    }

    @media (--vl) {
      grid-row: 1 / 5;
    }

    > .hatching {
      position: absolute;
      left: 0;
      transform: translateX(-50%);

      @media (--vs) {
        width: 3.125rem;
        bottom: 0;
      }

      @media (--vl) {
        width: 6.25rem;
        bottom: 25%;
      }
    }

    > .image {
      position: relative;
      width: 100%;
      aspect-ratio: 4 / 2.8;
      object-fit: cover;
      z-index: 2;
    }
  }
}
</style>

<style scoped lang="scss">
//html:has(.pg-teaser-big.-is-in-viewport) {
//  --base-color-background: var(--color-topaz-900);
//  --base-color-foreground: var(--color-topaz-050);
//}
</style>
