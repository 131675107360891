<template>
  <section ref="element" class="pg-teaser-hero-list">
    <div class="header">
      <h2 class="title font-xl-bold-vl font-md-bold-vs">
        {{ data.title }}
      </h2>
      <p class="lead font-lg-medium-vl font-md-medium-vs">
        {{ data.lead }}
      </p>
    </div>
    <ul class="list" data-cursor="arrow">
      <li v-for="(it, index) in data.listItems" :key="it.id" class="item">
        <hr ref="dividerElements" class="divider" />
        <hr
          v-if="index === data.listItems.length - 1"
          ref="dividerBottomElements"
          class="divider -bottom"
        />
        <div class="top">
          <MediaSvg v-if="it.pgSharedSvg" class="svg" :data="it.pgSharedSvg" />
          <h3
            v-if="it.title"
            ref="titleElements"
            class="title font-2xl-bold-vl font-lg-bold-vs"
          >
            {{ it.pgCtaLink.title }}
          </h3>
          <LinkHandler
            v-if="it.pgCtaLink?.url"
            class="link"
            :path="it.pgCtaLink.url"
            :aria-label="`Read more about: ${it.title}`"
          >
            <div class="label">
              <span class="text font-caps-xs-bold _hidden-vs">{{
                it.title
              }}</span>
              <span class="arrow link-arrow"></span>
            </div>
          </LinkHandler>
        </div>
        <LinkHandler
          v-if="it.pgCtaLink?.url"
          tabindex="-1"
          aria-hidden="true"
          class="bottom"
          :path="it.pgCtaLink.url"
        >
          <div class="label">
            <div class="inner">
              <span
                class="background"
                :style="{
                  backgroundImage: `url('/bg${(index % 3) + 1}.webp')`,
                }"
              />
              <p class="marqueetitle font-4xl-bold-vl font-3xl-bold-vs">
                {{ it.title }}
              </p>
            </div>
          </div>
        </LinkHandler>
      </li>
    </ul>
  </section>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import type { ParagraphPgTeaserHeroList } from '~/drupal/types';
import LinkHandler from '~/components/links/LinkHandler.vue';
import MediaSvg from '~/components/media/MediaSvg.vue';
import { useIntersectionState } from '~/utils/useIntersectionState';
import { parseCubicBezier } from '~/utils/parseCubicBezier';

defineProps<{
  data: ParagraphPgTeaserHeroList;
}>();

const titleElements = ref<HTMLElement[]>([]);
const dividerElements = ref<HTMLElement[]>([]);
const dividerBottomElements = ref<HTMLElement[]>([]);

const { play, isAnimationFinished } = fadeInAnimation();

const element = ref<null | HTMLElement>(null);
useIntersectionState(element, {
  onFirstEnter: play,
  onLeave() {
    isAnimationFinished.value = false;
  },
});

function fadeInAnimation() {
  const isAnimationFinished = ref<boolean>(false);
  const preferredMotion = usePreferredReducedMotion();
  const ease = parseCubicBezier('cubic-bezier(0.75,0,.25,1)');

  const tl = gsap.timeline({
    paused: true,
    onComplete: function () {
      isAnimationFinished.value = true;
    },
  });

  onMounted(function () {
    if (preferredMotion.value === 'reduce') {
      return;
    }

    const dividers = [...dividerElements.value, ...dividerBottomElements.value];
    const titleSplits = titleElements.value.map(function (i) {
      return new SplitText(i, {
        type: 'lines,words',
        linesClass: 'line',
        wordsClass: 'word',
      });
    });

    const titleLines = titleSplits.map((i) => i.lines);

    tl.fromTo(dividers, { scaleX: 0 }, { scaleX: 1, stagger: 0.1 })
      .fromTo(
        '.pg-teaser-hero-list .top > .svg',
        { rotate: -50, x: -500, opacity: 0 },
        {
          rotate: 0,
          x: 0,
          opacity: 1,
          stagger: 0.2,
          delay: 0.15,
          ease,
        },
        '<',
      )
      .fromTo(
        titleLines,
        { '--y': '100%' },
        { '--y': 0, stagger: 0.4, delay: 0.5, ease },
        '<',
      )
      .fromTo(
        '.pg-teaser-hero-list .top > .link',
        { opacity: -1, x: -100 },
        { opacity: 1, x: 0, stagger: 0.22, delay: 0.15, ease },
        '<',
      );
  });

  return {
    play,
    isAnimationFinished,
  };

  function play() {
    tl.play(0);
  }
}
</script>

<style scoped lang="scss">
// todo@alice: marquee animation soll immer neu beginnen

.pg-teaser-hero-list {
  --ease: cubic-bezier(0.75, 0, 0.25, 1);

  padding-top: var(--base-component-padding-block);
  padding-bottom: var(--base-component-padding-block);

  > .header {
    padding: var(--base-component-padding);
    display: grid;
    gap: 1.5rem;

    @media (--vl) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  > .list {
    > .item {
      position: relative;
      overflow: clip;

      @media (--vs) {
        --icon-size: 1.5rem;

        padding: 2.5rem var(--base-component-padding-inline);
        min-height: 6.875rem;
      }

      @media (--vl) {
        --icon-size: 2rem;

        padding: 2.5rem var(--base-component-padding-inline);
        height: 13.75rem;
      }

      @media (hover: hover) and (prefers-reduced-motion: no-preference) {
        > .top {
          > .svg {
            transition: transform 850ms;
            transform: translateY(0);
          }

          > .link {
            > .label > .arrow {
              transition: transform 850ms;
              transform: translateX(0);
            }
          }

          > .title {
            > :deep(.line) {
              transition: transform 850ms;

              &:nth-child(1) {
                transform: translateY(0);
                transition-delay: 0ms;
              }

              &:nth-child(2) {
                transform: translateY(0);
                transition-delay: 50ms;
              }

              &:nth-child(3) {
                transform: translateY(0);
                transition-delay: 100ms;
              }
            }
          }
        }

        &:hover {
          > .bottom > .label > .inner {
            clip-path: inset(0 0 0 0);

            > .background {
              transform: translate(0, 0);
            }

            > .marqueetitle {
              animation-play-state: running;
              opacity: 1;
            }
          }

          > .top {
            > .svg {
              transform: translateY(-80%) !important;
            }

            > .link {
              > .label > .arrow {
                transform: translateX(2rem);
              }
            }

            > .title {
              > :deep(.line) {
                &:nth-child(1) {
                  transform: translateY(-80%);
                }

                &:nth-child(2) {
                  transform: translateY(-90%);
                }

                &:nth-child(3) {
                  transform: translateY(-100%);
                }
              }
            }
          }
        }
      }

      > .divider {
        width: 100%;
        position: absolute;
        inset: 0 0 auto 0;
        height: 2px;
        border: none;
        outline: none;
        background-color: var(--color-topaz-050, #f4f4fb);
        margin: 0;
        transform-origin: left center;

        &.-bottom {
          inset: auto 0 0 0;
        }
      }

      > .top {
        display: grid;
        grid-template-columns: var(--icon-size) 1fr auto;
        align-items: center;
        height: 100%;

        @media (--vs) {
          gap: 1.5rem;
        }
        @media (--vl) {
          gap: 6rem;
        }

        > .svg {
          display: inline-block;
          width: var(--icon-size);
          height: var(--icon-size);
        }

        > .title {
          max-width: 20ch;

          :deep(.line) {
            overflow-y: clip;

            > .word {
              transform: translate(0, var(--y));
            }
          }
        }

        > .link {
          text-decoration: none;
          color: var(--color-topaz-500);

          > .label {
            display: flex;
            align-items: center;
            gap: 5rem;
          }
        }
      }

      > .bottom {
        display: none;

        @media (hover: hover) {
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;
          display: block;
          text-decoration: none;

          > .label {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;

            > .inner {
              width: 100%;
              height: 100%;
              overflow: clip;
              white-space: nowrap;
              clip-path: inset(100% 0 0 0);
              transition: clip-path 800ms var(--ease);

              > .marqueetitle {
                animation: marquee 10s linear infinite;
                color: var(--color-white);
                min-width: 20ch;
                animation-play-state: paused;
                animation-delay: -1s;
                opacity: 0;
                height: 100%;
                display: flex;
                align-items: center;
              }

              > .background {
                background-size: cover;
                width: 100%;
                height: 100%;
                position: absolute;
                inset: 0;
                transform: translate(0, 100%);
                transition: transform 1000ms var(--ease);
              }
            }
          }
        }
      }
    }
  }
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}
</style>
