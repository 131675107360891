<template>
  <article
    ref="headerHeroElement"
    class="header-hero-title-large"
    :class="{ '-modal-open': isModalOpen }"
  >
    <div
      ref="innerElement"
      class="inner"
      :class="{ '-modal-open': isModalOpen }"
      :style="{ '--top': scrollY }"
    >
      <h2 ref="titleElement" class="title font-display-3xl-zuehlke">
        {{ data.title }}
      </h2>
      <div
        ref="dialogWrapperElement"
        class="dialogwrapper hero-dialog-wrapper"
        :class="{ '-open': isModalOpen }"
      >
        <dialog ref="dialogElement" class="modal">
          <p
            ref="dialogTitleElement"
            class="title font-xl-bold-vl font-lg-bold-vs"
          >
            {{ data.title }}
          </p>
          <p
            ref="dialogTextElement"
            class="text font-xl-medium-vl font-md-medium-vs"
          >
            {{ data.text }}
          </p>
          <!--todo@alice: add correct link path-->
          <LinkHandler
            v-if="data.pgCtaLink"
            class="dialog-link button-tertiary"
            :path="data.pgCtaLink.url"
            ><span class="label">{{ data.pgCtaLink.title }}</span>
          </LinkHandler>
        </dialog>
        <button
          ref="dialogButtonElement"
          type="button"
          class="closebutton button-arrow-down"
          @click="toggleModal(false)"
        >
          <span class="arrow"></span>
          <span class="text">Close</span>
        </button>
      </div>
      <figure
        ref="figureLeftElement"
        class="figure -left"
        @click="toggleModal(true)"
      >
        <div class="imagewrapper" data-cursor="quicklook">
          <MediaImage
            v-if="leftImage"
            :data="leftImage"
            :style="'FOCAL_POINT32'"
            class="image image-left"
          />
        </div>
        <button class="button button-tertiary">Quick Look</button>
      </figure>
      <figure
        ref="figureRightElement"
        class="figure -right"
        @click="toggleModal(true)"
      >
        <div class="imagewrapper" data-cursor="quicklook">
          <MediaImage
            v-if="rightImage"
            :data="rightImage"
            :style="'FOCAL_POINT34'"
            class="image image-right"
          />
        </div>
      </figure>
      <div ref="ctaElement" class="cta"></div>
    </div>
  </article>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import type { ParagraphPgHeaderHero } from '~/drupal/types';
import LinkHandler from '~/components/links/LinkHandler.vue';
import { useIntersectionState } from '~/utils/useIntersectionState';
import { useHeroImages } from '~/components/paragraphs/header-hero/useHeroImages';
import { useModalToggle } from '~/components/paragraphs/header-hero/useModalToggle';
import { parseCubicBezier } from '~/utils/parseCubicBezier';
import { useImageParallax } from '~/components/paragraphs/header-hero/useImageParallax';

const props = defineProps<{
  data: ParagraphPgHeaderHero;
}>();

const { primaryImage: leftImage, secondaryImage: rightImage } = useHeroImages(
  props.data,
);

const innerElement = ref<HTMLDialogElement | null>(null);
const dialogElement = ref<HTMLDialogElement | null>(null);
const dialogWrapperElement = ref<HTMLElement | null>(null);
const dialogTitleElement = ref<HTMLElement | null>(null);
const dialogTextElement = ref<HTMLElement | null>(null);
const dialogButtonElement = ref<HTMLElement | null>(null);
const titleElement = ref<HTMLElement | null>(null);
const figureLeftElement = ref<HTMLElement | null>(null);
const figureRightElement = ref<HTMLElement | null>(null);
const ctaElement = ref<HTMLElement | null>(null);
const headerHeroElement = ref<HTMLElement | null>(null);

const { isVs } = useViewportSize();
const { y: scrollY } = useWindowScroll();
const preferredMotion = usePreferredReducedMotion();

const { tlModal } = animations();

useImageParallax(headerHeroElement);

const { toggleModal, isModalOpen } = useModalToggle(dialogElement, {
  onOpen: function () {
    if (preferredMotion.value === 'reduce') {
      tlModal.progress(1);
    } else {
      tlModal.timeScale(1).play(0);
    }
  },
  onClose: function () {
    if (preferredMotion.value === 'reduce') {
      tlModal.progress(0);
    } else {
      tlModal.timeScale(1.5).reverse();
    }
  },
});

function animations() {
  const ease = parseCubicBezier('cubic-bezier(0.75,0,.25,1)');

  const elements: Record<string, null | HTMLElement> = {
    inner: null,
    title: null,
    figureLeft: null,
    figureRight: null,
    dialogWrapper: null,
    dialogTitle: null,
    dialogText: null,
    dialogButton: null,
    cta: null,
  };

  const titleSplit = ref<SplitText | null>(null);

  const tlModal = gsap.timeline({
    paused: true,
  });

  const tlFadeIn = gsap.timeline({
    paused: true,
    onComplete: setModalAnimation,
  });

  useIntersectionState(innerElement, {
    onFirstEnter: () => tlFadeIn.play(0),
  });

  onMounted(function () {
    titleSplit.value = new SplitText(titleElement.value, {
      type: 'lines,words',
      linesClass: 'line',
      wordsClass: 'word',
    });

    elements.inner = innerElement.value;
    elements.title = titleElement.value;
    elements.figureLeft = figureLeftElement.value;
    elements.figureRight = figureRightElement.value;
    elements.dialogWrapper = dialogWrapperElement.value;
    elements.dialogTitle = dialogTitleElement.value;
    elements.dialogText = dialogTextElement.value;
    elements.dialogButton = dialogButtonElement.value;
    elements.cta = ctaElement.value;

    setFadeInAnimation();
  });

  return {
    tlModal,
    tlFadeIn,
  };

  function setFadeInAnimation() {
    const duration = 2;

    tlFadeIn
      .fromTo(
        titleSplit.value?.lines ?? [],
        {
          '--y': '-130%',
        },
        {
          '--y': '0%',
          ease,
          stagger: 0.05,
          duration,
        },
      )
      .fromTo(
        elements.figureLeft,
        {
          clipPath: 'inset(20% 100% 0% 0%)',
        },
        {
          clipPath: 'inset(0% 0% 0% 0%)',
          ease,
          duration,
        },
        '<',
      )
      .fromTo(
        elements.figureRight,
        {
          clipPath: 'inset(20% 0% 0% 100%)',
        },
        {
          clipPath: 'inset(0% 0% 0% 0%)',
          ease,
          duration,
        },
        '<',
      );
  }

  function setModalAnimation() {
    if (isVs.value) {
      setModalAnimationVs();
    } else {
      setModalAnimationVl();
    }
  }

  function setModalAnimationVs() {}

  function setModalAnimationVl() {
    const duration = 1.75;
    const firstRowHeight = elements.title?.clientHeight ?? 0;
    const toTop = firstRowHeight;

    tlModal
      .fromTo(
        titleSplit.value?.words ?? [],
        {
          y: '0%',
        },
        {
          y: '-110%',
          ease,
          duration,
        },
      )
      .fromTo(
        elements.figureLeft,
        {
          y: 0,
        },
        {
          y: window.innerHeight,
          ease,
          duration,
        },
        '<',
      )
      .fromTo(
        [elements.figureRight],
        {
          y: 0,
        },
        {
          y: toTop * -1,
          ease,
          duration,
        },
        '<',
      )
      .fromTo(
        [elements.dialogWrapper],
        {
          y: window.innerHeight * -0.5,
        },
        {
          y: toTop * -1,
          ease,
          duration,
        },
        '<',
      )
      .fromTo(
        [elements.cta],
        {
          y: 0,
        },
        {
          y: toTop * -1,
          ease,
          duration,
        },
        '<',
      )
      .fromTo(
        elements.cta,
        {
          'clip-path': 'inset(100% 0 0% 0)',
        },
        {
          'clip-path': 'inset(0% 0 0% 0)',
          ease,
          duration,
        },
        '<',
      )
      .fromTo(
        elements.dialogButton,
        {
          y: 100,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          ease,
          duration,
        },
        '<',
      )
      .fromTo(
        [
          elements.dialogText,
          elements.dialogTitle,
          '.header-hero-title-large .dialog-link',
        ],
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
        },
        '<25%',
      );
  }
}
</script>

<style scoped lang="scss">
// style general
.header-hero-title-large {
  overflow-x: clip;

  > .inner {
    display: grid;
    grid-template-columns: 878fr 622fr;
    grid-template-areas:
      'title title'
      'image-left image-right'
      'none cta';

    &.-modal-open {
      position: fixed;
      z-index: 110;
      inset: var(--top) 0 0 0;
    }

    > .figure {
      cursor: pointer;

      &.-left {
        grid-area: image-left;
      }

      &.-right {
        grid-area: image-right;
      }

      > .imagewrapper {
        width: 100%;
        height: 100%;
        overflow: clip;

        > .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
          transform-origin: center center;
        }
      }

      > .button {
        float: right;
      }
    }

    > .title {
      grid-area: title;
      height: 100%;
      line-height: 120%;

      :deep(.line) {
        overflow-y: clip;

        > .word {
          transform: translateY(var(--y));
        }
      }
    }

    > .dialogwrapper {
      grid-row: 2 / 4;
      grid-column: 1 / 2;
    }

    > .cta {
      grid-area: cta;
      background-color: #241937;
      color: var(--color-white);
      display: flex;
      align-items: center;
      justify-content: center;
      height: var(--height-row1);
    }
  }
}

// style vs
.header-hero-title-large {
  @media (--vs) {
    padding-bottom: 4rem;

    > .inner {
      > .title {
        padding: 11rem var(--base-component-padding-inline) 1.5rem
          var(--base-component-padding-inline);
      }

      > .figure {
        height: fit-content;

        &.-left {
          aspect-ratio: 244 / 327;
        }

        &.-right {
          aspect-ratio: 156 / 364;
        }

        > .button {
          margin: 0.7rem 1.5rem 0 0;
        }
      }
    }
  }
}

//style vl
.header-hero-title-large {
  @media (--vl) {
    --height-row1: 20rem;
    --height-row2: calc(100lvh - 20rem);
    --height-total: 100lvh;

    height: var(--height-total);

    > .inner {
      grid-template-rows: var(--height-row1) var(--height-row2) 0;
      min-height: var(--height-total);
      overflow-y: clip;

      &.-modal-open {
        > .figure {
          pointer-events: none;
        }

        > .title {
          z-index: 1000;
        }
      }

      > .title {
        padding: 4rem var(--base-component-padding-inline);
        max-width: 25ch;
      }

      > .figure {
        &.-left {
          height: fit-content;
          aspect-ratio: 878 / 489;

          > .imagewrapper {
            > .image {
              max-height: 50svh;
            }
          }
        }

        &.-right {
          height: var(--height-row2);
          width: 100%;
        }

        > .button {
          margin: 1.15rem 2rem 0 0;
        }
      }
    }
  }
}
</style>
