<template>
  <div ref="element" class="pg-media">
    <mark>pg-media</mark>
  </div>
</template>

<script setup lang="ts">
import { useIntersectionState } from '~/utils/useIntersectionState';

defineProps<{
  data: object;
}>();

const element = ref<null | HTMLElement>(null);
useIntersectionState(element);
</script>

<style scoped lang="scss"></style>
