export function useModalToggle(
  dialogElement: Ref<HTMLDialogElement | HTMLElement | null>,
  callbacks: {
    onOpen: () => void;
    onClose: () => void;
  },
) {
  const windowEl = ref<Window | null>(null);
  const isModalOpen = ref<boolean>(false);

  const isLocked = useScrollLock(windowEl);

  onMounted(function () {
    windowEl.value = window;
  });

  watch(isModalOpen, function () {
    if (isModalOpen.value) {
      callbacks.onOpen();
      isLocked.value = true;
    } else {
      callbacks.onClose();
      isLocked.value = false;
    }
  });

  return {
    toggleModal,
    isModalOpen,
    isLocked,
  };

  function toggleModal(open = !isModalOpen.value) {
    isModalOpen.value = open;

    if (isModalOpen.value) {
      showDialog();
    } else {
      hideDialog();
    }
  }

  function showDialog() {
    if (dialogElement.value instanceof HTMLDialogElement) {
      dialogElement.value.show();
    } else if (dialogElement.value instanceof HTMLElement) {
      dialogElement.value.setAttribute('data-open', 'true');
    }
  }
  function hideDialog() {
    if (dialogElement.value instanceof HTMLDialogElement) {
      dialogElement.value.close();
    } else if (dialogElement.value instanceof HTMLElement) {
      dialogElement.value.setAttribute('data-open', 'false');
    }
  }
}
