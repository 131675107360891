<template>
  <svg
    class="quote-mark-start"
    width="47"
    height="40"
    viewBox="0 0 47 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9826 3.47499C19.9826 4.29166 19.516 5.225 18.5826 6.275C17.766 7.20833 16.8326 8.25833 15.7826 9.425C14.8493 10.475 13.916 11.7 12.9826 13.1C12.166 14.5 11.7576 16.075 11.7576 17.825C11.7576 19.3417 12.1076 20.625 12.8076 21.675C13.6243 22.6083 14.441 23.5417 15.2576 24.475C16.191 25.4083 17.0076 26.4583 17.7076 27.625C18.5243 28.675 18.9326 30.075 18.9326 31.825C18.9326 34.1583 18.116 36.0833 16.4826 37.6C14.966 39 13.0993 39.7 10.8826 39.7C8.89929 39.7 7.20762 39.2917 5.80762 38.475C4.52428 37.6583 3.41595 36.6083 2.48262 35.325C1.66595 33.925 1.02428 32.4083 0.557617 30.775C0.207617 29.025 0.0326176 27.3333 0.0326176 25.7C0.0326176 21.6167 0.790951 17.9417 2.30762 14.675C3.82428 11.4083 5.98262 8.31666 8.78262 5.4C9.71595 4.46666 10.941 3.35833 12.4576 2.075C14.091 0.791663 15.6076 0.149994 17.0076 0.149994C18.991 0.149994 19.9826 1.25833 19.9826 3.47499ZM46.0576 3.125C46.0576 3.94166 45.591 4.87499 44.6576 5.92499C43.841 6.85833 42.9076 7.96666 41.8576 9.24999C40.8076 10.4167 39.816 11.7 38.8826 13.1C38.066 14.5 37.6576 16.075 37.6576 17.825C37.6576 19.3417 38.0076 20.625 38.7076 21.675C39.5243 22.6083 40.341 23.5417 41.1576 24.475C42.091 25.4083 42.9076 26.4583 43.6076 27.625C44.4243 28.675 44.8326 30.075 44.8326 31.825C44.8326 34.1583 44.016 36.0833 42.3826 37.6C40.866 39 38.941 39.7 36.6076 39.7C34.741 39.7 33.1076 39.2917 31.7076 38.475C30.4243 37.5417 29.316 36.4333 28.3826 35.15C27.566 33.75 26.9243 32.2333 26.4576 30.6C26.1076 28.9667 25.9326 27.3333 25.9326 25.7C25.9326 21.2667 26.9243 17.0667 28.9076 13.1C30.891 9.13333 33.6326 5.80833 37.1326 3.125C37.8326 2.54166 38.7076 1.9 39.7576 1.19999C40.9243 0.499993 41.9743 0.149994 42.9076 0.149994C43.7243 0.149994 44.4243 0.44166 45.0076 1.02499C45.7076 1.49166 46.0576 2.19166 46.0576 3.125Z"
      fill="#965B9A"
    />
  </svg>
</template>

<style scoped lang="scss">
.quote-mark-start {
  user-select: none;
  pointer-events: none;
}
</style>
