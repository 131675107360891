import type { ParagraphPgHeaderHero } from '~/drupal/types';

export function useHeroImages(data: ParagraphPgHeaderHero) {
  const primaryImage = computed(function () {
    if (data.primaryImage) {
      return data.primaryImage;
    }

    return null;
  });

  const secondaryImage = computed(function () {
    if (data.secondaryImage) {
      return data.secondaryImage;
    }

    return null;
  });

  return {
    primaryImage,
    secondaryImage,
  };
}
