import type { Component } from 'vue';
import PgMedia from '~/components/paragraphs/PgMedia.vue';
import PgTeaserBig from '~/components/paragraphs/PgTeaserBig.vue';
import PgHeaderHero from '~/components/paragraphs/PgHeaderHero.vue';
import PgQuote from '~/components/paragraphs/PgQuote.vue';
import PgCtaDefault from '~/components/paragraphs/PgCtaDefault.vue';
import PgTeaserCarousel from '~/components/paragraphs/PgTeaserCarousel.vue';
import PgTeaserHeroList from '~/components/paragraphs/PgTeaserHeroList.vue';
import PgTeaserBigSplit from '~/components/paragraphs/PgTeaserBigSplit.vue';

export const Paragraphs: Record<string, Component> = {
  ParagraphPgMedia: PgMedia,
  ParagraphPgTeaserBig: PgTeaserBig,
  ParagraphPgHeaderHero: PgHeaderHero,
  ParagraphPgQuote: PgQuote,
  ParagraphPgCtaDefault: PgCtaDefault,
  ParagraphPgTeaserCarousel: PgTeaserCarousel,
  ParagraphPgTeaserHeroList: PgTeaserHeroList,
  ParagraphPgTeaserBigSplit: PgTeaserBigSplit,
};
