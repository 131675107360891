<template>
  <svg
    class="svg-blurry-blob"
    xmlns="http://www.w3.org/2000/svg"
    width="1532"
    height="1168"
    viewBox="0 0 1532 1168"
    fill="none"
  >
    <g
      style="mix-blend-mode: hard-light"
      opacity="0.3"
      :filter="`url(#${idFilter})`"
    >
      <path
        d="M231.338 360.802L743.998 83L1140.5 114.146L1330 300.926L1191.5 543.146L925 622.5L1044 871.146L587.5 1072.65L301.746 904L191 655.866L231.338 360.802Z"
        :fill="`url(#${idPaint})`"
      />
    </g>
    <defs>
      <filter
        :id="idFilter"
        x="-49"
        y="-157"
        width="1619"
        height="1469.65"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="120"
          result="effect1_foregroundBlur_2403_316"
        />
      </filter>
      <linearGradient
        :id="idPaint"
        x1="345.733"
        y1="128.646"
        x2="1149.58"
        y2="1200.46"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#995B9C" offset="0" />
        <stop offset="1" stop-color="#046588" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script setup lang="ts">
const idFilter = 'filter_' + Math.random().toString(16).slice(2);
const idPaint = 'filter_' + Math.random().toString(16).slice(2);
</script>

<style scoped lang="scss">
.svg-blurry-blob {
  mix-blend-mode: hard-light;
  filter: blur(10px);
  user-select: none;
  pointer-events: none;
}
</style>
