import type { Ref } from 'vue';
import type { UseIntersectionObserverOptions } from '@vueuse/core';

export function useIntersectionState(
  target: Ref<HTMLElement | null>,
  callbacks?: {
    onFirstEnter?: () => void;
    onEnter?: () => void;
    onLeave?: () => void;
  },
  options?: UseIntersectionObserverOptions,
) {
  const targetIsVisible = ref<boolean>(false);
  const wasInViewport = ref<boolean>(false);

  const finalOptions = {
    ...{
      threshold: 0.25,
    },
    ...(options ?? {}),
  };

  useIntersectionObserver(
    target,
    ([{ isIntersecting, target }]) => {
      targetIsVisible.value = isIntersecting;

      if (isIntersecting) {
        target.classList.add('-was-in-viewport');
        target.classList.add('-is-in-viewport');

        if (callbacks?.onEnter) {
          callbacks.onEnter();
        }

        if (!wasInViewport.value && callbacks?.onFirstEnter) {
          callbacks.onFirstEnter();
        }

        wasInViewport.value = true;
      } else {
        target.classList.remove('-is-in-viewport');

        if (callbacks?.onLeave) {
          callbacks.onLeave();
        }
      }
    },
    finalOptions,
  );
}
