import gsap from 'gsap';

export function useImageParallax(parentEl: Ref<HTMLElement | null>) {
  onMounted(function () {
    if (!parentEl.value) {
      return;
    }

    const images = Array.from(parentEl.value.querySelectorAll('img'));

    const tl = gsap.timeline({
      paused: true,
      scrollTrigger: {
        scrub: 0,
        trigger: parentEl.value,
      },
    });

    gsap.set(images, {
      scale: 1.3,
    });

    tl.fromTo(
      images,
      {
        y: '-20%',
      },
      {
        y: '20%',
      },
    );
  });
}
