<template>
  <section ref="element" class="pg-teaser-carousel">
    <div class="header">
      <h2 class="title">{{ data.title }}</h2>
    </div>
    <div class="main -vl _hidden-vs">
      <div class="count">
        <button
          v-for="(it, index) in data.carouselItems"
          :key="`count-${it.id}`"
          class="button"
          type="button"
          :aria-label="`Slide to teaser with id ${it.id}`"
          :class="{ '-active': activeTeaserIndex === index }"
          @click="slideToTeaser(index)"
        >
          {{ index + 1 }}
        </button>
        <!--        <button-->
        <!--          type="button"-->
        <!--          class="button -plus font-md-bold-vl"-->
        <!--          :aria-label="`Last Carousel Slide`"-->
        <!--          :class="{ '-active': activeTeaserIndex === lastSlideIndex }"-->
        <!--          @click="slideToTeaser(lastSlideIndex)"-->
        <!--        >-->
        <!--          +-->
        <!--        </button>-->
      </div>
      <div class="carousel">
        <div
          class="graphics"
          :class="{ '-half': activeTeaserIndex !== lastSlideIndex }"
        >
          <SvgHatching class="hatching" />
        </div>
        <TeaserCarouselItem
          v-for="(it, index) in data.carouselItems"
          :key="it.id"
          class="item"
          :data="it"
          :index="index"
          :class="{
            '-previous': previousActiveTeaserIndex === index,
            '-active': activeTeaserIndex === index,
            '-active-left': teaserSiblingIndexes.left === index,
            '-active-right': teaserSiblingIndexes.right === index,
            '-active-right-next': teaserSiblingIndexes.rightNext === index,
            '-left': activeTeaserIndex > index,
            '-right': activeTeaserIndex < index,
          }"
        />
        <!--        <TeaserCarouselCta-->
        <!--          class="item"-->
        <!--          :title="data.fieldLastSlideTitle"-->
        <!--          :text="data.fieldLastSlideText"-->
        <!--          :cta="data.fieldLastSlideCtaLink"-->
        <!--          :class="{-->
        <!--            '-previous': previousActiveTeaserIndex === lastSlideIndex,-->
        <!--            '-active': activeTeaserIndex === lastSlideIndex,-->
        <!--            '-active-left': teaserSiblingIndexes.left === lastSlideIndex,-->
        <!--            '-active-right': teaserSiblingIndexes.right === lastSlideIndex,-->
        <!--            '-active-right-next':-->
        <!--              teaserSiblingIndexes.rightNext === lastSlideIndex,-->
        <!--            '-left': activeTeaserIndex > lastSlideIndex,-->
        <!--            '-right': activeTeaserIndex < lastSlideIndex,-->
        <!--          }"-->
        <!--        />-->
      </div>
    </div>
    <div class="main -vs _hidden-vl">
      <div
        v-for="t in data.fieldCarouselItems"
        :key="`vs-${t.id}`"
        class="teaser teaser-vs"
      >
        <p v-if="t.fieldEyebrow" class="eyebrow font-caps-xs-bold">
          {{ t.fieldEyebrow }}
        </p>
        <h3 v-if="t.fieldTitle" class="title font-2xl-bold">
          {{ t.fieldTitle }}
        </h3>
        <div class="figure">
          <MediaImage
            :id="t.fieldPrimaryImage.id"
            class="mediaimage"
            loading="lazy"
            :style="'FOCAL_POINT_4_3'"
          />
        </div>
        <p v-if="t.fieldLead" class="text font-lg-medium-vl font-md-medium-vs">
          {{ t.fieldLead }}
        </p>
      </div>
      <!--      <TeaserCarouselCta-->
      <!--        class="teaser"-->
      <!--        :title="data.fieldLastSlideTitle"-->
      <!--        :text="data.fieldLastSlideText"-->
      <!--        :cta="data.fieldLastSlideCtaLink"-->
      <!--      />-->
    </div>
  </section>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import TeaserCarouselItem from '~/components/paragraphs/components/TeaserCarouselItem.vue';
import type { ParagraphPgTeaserCarousel } from '~/drupal/types';
import SvgHatching from '~/components/paragraphs/svgs/SvgHatching.vue';
import { useIntersectionState } from '~/utils/useIntersectionState';
import { useViewportSize } from '~/utils/useViewportSize';

const props = defineProps<{
  data: ParagraphPgTeaserCarousel;
}>();

const previousActiveTeaserIndex = ref<number>(0);
const activeTeaserIndex = ref<number>(0);

const teaserElements = ref<HTMLElement[]>([]);

const teaserSiblingIndexes = computed(function () {
  return {
    left: activeTeaserIndex.value - 1,
    right: activeTeaserIndex.value + 1,
    rightNext: activeTeaserIndex.value + 2,
  };
});

const lastSlideIndex = computed(function () {
  return props.data.carouselItems.length;
});

const totalTeaserItems = computed(function () {
  return props.data.carouselItems.length;
});

const { play, pause, resetCount } = autoPlay();

const element = ref<null | HTMLElement>(null);

useIntersectionState(
  element,
  {
    onEnter: play,
    onLeave: pause,
  },
  {
    threshold: 0.25,
  },
);

onMounted(function () {
  teaserElements.value = gsap.utils.toArray('.carousel > .item');
});

provide('slideToTeaser', slideToTeaser);

function slideToTeaser(index: number) {
  if (index < totalTeaserItems.value) {
    previousActiveTeaserIndex.value = activeTeaserIndex.value;
    activeTeaserIndex.value = index;

    resetCount();
  }
}

function autoPlay() {
  let counter: number | null;

  const params = {
    durationMs: 1000,
  };

  const state = ref({
    count: 0,
    isCounting: false,
  });

  const hasCarouselEnded = computed(function () {
    return activeTeaserIndex.value === totalTeaserItems.value - 1;
  });

  const { isVs } = useViewportSize();

  return { play, pause, resetCount };

  function play() {
    if (hasCarouselEnded.value || isVs.value) {
      return;
    }

    if (!counter) {
      countUp();
    }
  }

  function pause() {
    if (typeof counter === 'number') {
      state.value.count = 0;
      window.cancelAnimationFrame(counter);
      counter = null;
    }
  }

  function countUp() {
    state.value.count += 1;

    if (state.value.count === params.durationMs) {
      triggerNextSlide();
    } else {
      counter = window.requestAnimationFrame(countUp);
    }
  }

  function triggerNextSlide() {
    resetCounter(true);
    slideToTeaser(activeTeaserIndex.value + 1);
  }

  function resetCount() {
    state.value.count = 0;
  }
  function resetCounter(replay: boolean = false) {
    state.value.count = 0;

    if (typeof counter === 'number') {
      window.cancelAnimationFrame(counter);
      counter = null;

      if (replay) {
        play();
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pg-teaser-carousel {
  --ease: cubic-bezier(0.75, 0, 0.25, 1);

  position: relative;
  padding-bottom: 3.25rem;

  > .header {
    @media (--vs) {
      padding: var(--base-component-padding);
    }

    @media (--vl) {
      display: grid;
      grid-template-columns: 40vw 40vw 1fr;
      padding: 4rem var(--base-component-padding-inline) 4rem 0;

      > .title {
        padding-left: var(--base-component-padding-inline);
      }
    }
  }

  > .main.-vl {
    position: relative;
    max-width: 100%;
    overflow-x: clip;

    > .count {
      position: absolute;
      z-index: 20;
      margin-left: var(--base-component-padding-inline);

      > .button {
        border: 1.5px solid transparent;
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        background-color: transparent;
        color: var(--base-color-foreground);
        cursor: pointer;

        &.-active {
          border-color: var(--base-color-foreground);
          color: var(--base-color-foreground);
        }
      }
    }

    > .carousel {
      --height: 50rem;
      --width: 80vw;

      height: var(--height);
      position: relative;

      > .item {
        position: absolute;
        inset: 0 auto 0 0;
        height: var(--height);
        width: var(--width);
      }

      > .graphics {
        position: absolute;
        inset: auto 0 auto var(--width);
        height: 100%;
        width: 6.25rem;
        z-index: 10;
        transform: translateX(-50%);
        display: flex;
        clip-path: inset(0 0 0 0);
        transition: clip-path 1000ms var(--ease);

        &.-half {
          clip-path: inset(0 0 0 50%);
        }

        > .hatching {
          width: 6.25rem;
          height: fit-content;
          margin: auto;
        }
      }
    }
  }

  > .main.-vs {
    position: relative;
  }
}

.teaser-vs {
  &:not(:last-of-type) {
    padding-bottom: 6rem;
  }

  > .eyebrow {
    padding: 0 var(--base-component-padding-inline);
  }

  > .title {
    padding: 1rem var(--base-component-padding-inline);
  }

  > .figure {
    > .mediaimage {
      width: calc(100% - 3.5rem);
      aspect-ratio: 21.5 / 12.625;
      object-fit: cover;
    }
  }

  > .text {
    padding: 1rem var(--base-component-padding-inline) 0
      var(--base-component-padding-inline);
  }
}
</style>
