<template>
  <svg
    class="quote-mark-end"
    width="47"
    height="40"
    viewBox="0 0 47 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.675 14.15C20.675 18.35 19.8583 22.0833 18.225 25.35C16.7083 28.6167 14.4917 31.7667 11.575 34.8C10.6417 35.7333 9.41667 36.7833 7.9 37.95C6.38333 39.1167 4.98333 39.7 3.7 39.7C2.88333 39.7 2.125 39.4667 1.425 39C0.841666 38.4167 0.549999 37.7167 0.549999 36.9C0.549999 35.9667 0.958333 35.0333 1.775 34.1C2.70833 33.05 3.7 31.9417 4.75 30.775C5.8 29.6083 6.73333 28.325 7.55 26.925C8.48333 25.525 8.95 23.95 8.95 22.2C8.95 20.8 8.54167 19.575 7.725 18.525C7.025 17.3583 6.20833 16.3083 5.275 15.375C4.34167 14.325 3.46667 13.275 2.65 12.225C1.95 11.0583 1.6 9.71666 1.6 8.2C1.6 5.98333 2.35833 4.11666 3.875 2.59999C5.50833 0.96666 7.43333 0.149994 9.65 0.149994C11.5167 0.149994 13.15 0.558329 14.55 1.375C15.95 2.19166 17.1167 3.3 18.05 4.7C18.9833 5.98333 19.625 7.49999 19.975 9.24999C20.4417 10.8833 20.675 12.5167 20.675 14.15ZM46.575 14.15C46.575 22.55 43.3083 29.6667 36.775 35.5C35.8417 36.3167 34.7333 37.25 33.45 38.3C32.1667 39.2333 30.8833 39.7 29.6 39.7C28.9 39.7 28.2 39.4667 27.5 39C26.8 38.4167 26.45 37.7167 26.45 36.9C26.45 35.9667 26.8583 35.0333 27.675 34.1C28.6083 33.05 29.6 31.9417 30.65 30.775C31.7 29.6083 32.6333 28.325 33.45 26.925C34.3833 25.525 34.85 23.95 34.85 22.2C34.85 20.6833 34.4417 19.4 33.625 18.35C32.925 17.3 32.1083 16.3083 31.175 15.375C30.2417 14.4417 29.3667 13.45 28.55 12.4C27.85 11.2333 27.5 9.83333 27.5 8.2C27.5 5.86666 28.3167 3.94166 29.95 2.425C31.5833 0.908328 33.5667 0.149994 35.9 0.149994C37.7667 0.149994 39.3417 0.616661 40.625 1.55C42.025 2.36666 43.1333 3.47499 43.95 4.875C44.8833 6.15833 45.525 7.67499 45.875 9.425C46.3417 11.0583 46.575 12.6333 46.575 14.15Z"
      fill="#965B9A"
    />
  </svg>
</template>

<style scoped lang="scss">
.quote-mark-end {
  user-select: none;
  pointer-events: none;
}
</style>
