<template>
  <svg
    class="svg-cross"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 0H7V7H0V9H7V16H9V9H16V7H9V0Z"
      fill="#7E7B8D"
    />
  </svg>
</template>

<style scoped lang="scss">
.svg-cross {
  user-select: none;
  pointer-events: none;
}
</style>
